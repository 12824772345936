import React, { lazy, Suspense } from "react"
import { Link } from "react-router-dom"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { AppWebPaths } from "../../../common/paths/AppWebPaths"
import { Colors } from "../../theme/Colors"
import { ContainerPlaceholder } from "../lazy/assets/fallbacks/ContainerPlaceholder"

const Logo = lazy(() => import('../lazy/assets/Logo'))

const { styles } = createStyleSheet({

    navContainer: {

        background: Colors.mainInteract,
        height: '80px',
        padding: '10px 0px',
        borderBottom: '3px solid '+Colors.mainOutliner,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        color: Colors.interactText

    },

    navMenu: {

        display: 'inline-block',
        verticalAlign: 'top',
        height: '100%',
        float: 'right',
        paddingRight: '40px',

        '& ul': {

            color: Colors.interactText,
            display: 'inline-block',
            verticalAlign: 'top',
            listStyleType: 'none',
            height: '100%',
            margin: 0,
            padding: 0,

            '& li': {

                display: 'inline-block',
                verticalAlign: 'top',
                height: '100%',
                width: 'fit-content',
                padding: '0 15px',

                '& a': {

                    fontSize: '22px',
                    display: 'block',
                    height: '100%',
                    width: '100%',
                    lineHeight: '70px',
                    textAlign: 'center',
                    fontWeight: 'bold',

                }

            }

        }

    },

    navLogo: {

        width: '200px',
        height: '80px',
        marginLeft: '5px',
        display: 'inline-block',
        verticalAlign: 'top',

    },

})

export const NavigationPanel = () => {

    const styled = useStyle(styles)

    return <div className={styled('navContainer')}>

        <Link to={AppWebPaths.main} className={styled('navLogo')} aria-label='Cursive Information Consulting Home page'>
            <Suspense fallback={
                <ContainerPlaceholder className={styled('logopic', 'navLogo')}/>
            }>
                <Logo className={styled('navLogo')}/>
            </Suspense>
        </Link>
        <nav className={styled('navMenu')}>
            <ul>
                <li><Link to='#'>Products</Link></li>
                <li><Link to='#'>Solutions</Link></li>
                <li><Link to='#'>Jobs</Link></li>
                <li><Link to={AppWebPaths.contact}>Contacts</Link></li>
            </ul>
        </nav>
        
    </div>

}