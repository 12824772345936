import React from "react"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { ExtendHTMLProps } from "../../../types/ExtendProps"

const bg = require('../../../../../assets/img/headbanner.webp')

const { styles } = createStyleSheet({

    headingBg: {

        background: `url(${bg.default})`,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%'

    }

})

const HeadingBackground = (props: ExtendHTMLProps<HTMLDivElement>) => {

    const styled = useStyle(styles)

    return <div {...props} className={styled('headingBg', props.className as unknown as null || null)}>{
        props.children || null
    }</div>

}

export default HeadingBackground