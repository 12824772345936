import React, { lazy, Suspense } from "react"
import { Link } from "react-router-dom"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { AppWebPaths } from "../../../common/paths/AppWebPaths"
import { Colors } from "../../theme/Colors"
import { ContainerPlaceholder } from "../lazy/assets/fallbacks/ContainerPlaceholder"

const HeadingSeparator = lazy(() => import('../lazy/assets/HeadingSeparator'))

const HeadingBackground = lazy(() => import('../lazy/assets/HeadingBackground'))

const { styles } = createStyleSheet({

    headingContainer: {

        width: '100%',
        margin: 0,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',

        height: '540px',
        background: Colors.mainInteractDark,

        media: [
            {
                "max-width": '1440px',
                css: { height: '405px' }
            },
            {
                "max-width": '1280px',
                css: { height: '360px' }
            },
            {
                "max-width": '960px',
                css: { height: '270px' }
            },
            {
                "max-width": '800px',
                css: { height: '225px' }
            },
            {
                "max-width": '600px',
                css: { height: '168px' }
            },
            {
                "max-width": '480px',
                css: { height: '135px' }
            },
        ],

        position: 'relative',

    },

    headSepAdjust: {

        position: 'absolute',
        bottom: '-7vw',
        width: '100%',
        height: '15vw',

    },

    headBgAdjust: {

        height: '100%',
        width: '100%',
        position: 'absolute',

    },

    headBgAnimated: {

        keyframes: [{

            name: 'headBgAppear',
            steps: [{
                from: {
                    opacity: 0,
                    right: '-100px'
                },
                to: {
                    opacity: 1,
                    right: '0px'
                }
            }]

        }],

        opacity: 0,
        right: '-100px',
        animationName: 'headBgAppear',
        animationDuration: '2s',
        animationFillMode: 'forwards',
        animationTimingFunction: 'linear'

    },

    headingText: {

        margin: '0',
        position: 'relative',
        top: '120px',
        left: '50px',
        width: '640px',
        display: 'block',
        color: Colors.interactText,
        fontSize: '48px',
        fontWeight: 'bold',
        textShadow: '0px 0px 20px #000',
        transformOrigin: 'top left',

        animationName: 'headTextAppear',
        animationDuration: '.3s',

        keyframes: [{
            name: 'headTextAppear',
            steps: [{
                from: {
                    transform: 'scale(0.7, 1)',
                    marginLeft: '-30px',
                    opacity: 0.3
                },
                to: {
                    transform: 'scale(1,1)',
                    marginLeft: '0',
                    opacity: 1
                }
            }]
        }],

        media: [
            {
                "max-width": '1440px',
                css: { fontSize: '32px !important', width: '520px !important', top: '80px !important' }
            },
            {
                "max-width": '1280px',
                css: { top: '60px !important' }
            },
            {
                "max-width": '1024px',
                css: { top: '32px !important', left: '32px !important' }
            },
            {
                "max-width": '960px',
                css: { width: '460px !important' }
            },
            {
                "max-width": '800px',
                css: { fontSize: '22px !important', width: '420px !important' }
            }
        ]

    },

    textUpper: { textTransform: 'uppercase' },

    c2a: {

        display: 'block',
        position: 'relative',
        top: '160px',
        marginTop: '20px',
        left: '50px',
        width: '240px',
        height: '80px',
        textAlign: 'center',
        lineHeight: '80px',
        background: Colors.mainInteract,
        color: Colors.interactText,
        fontSize: '22px',
        border: '2px solid '+Colors.mainOutliner,
        zIndex: 2,
        transition: 'background .3s',

        keyframes: [{
            name: 'c2aAppear',
            steps: [
                {
                    percentage: '0',
                    css: { opacity: 0 }
                },
                {
                    percentage: '30',
                    css: { opacity: 1 }
                },
                {
                    percentage: '80',
                    css: {
                        transform: 'rotateZ(0deg) scale(1,1)',
                        borderRadius: '0px'
                    }
                },
                {
                    percentage: '85',
                    css: {
                        transform: 'rotateZ(10deg) scale(0.9,0.9)',
                    }
                },
                {
                    percentage: '90',
                    css: {
                        transform: 'rotateZ(0deg) scale(1,1)',
                        borderRadius: '30px'
                    }
                },
                {
                    percentage: '95',
                    css: {
                        transform: 'rotatez(-10deg) scale(0.9,0.9)'
                    }
                },
                {
                    percentage: '100',
                    css: {
                        transform: 'rotateZ(0deg) scale(1,1)',
                        borderRadius: '0px'
                    }
                }
            ]
        }],

        animationName: 'c2aAppear',
        animationDuration: '3s',

        '&:hover': {

            background: Colors.mainInteractHighlight,

        },

        media: [
            {
                "max-width": '1440px',
                css: {
                    
                    width: '180px',
                    height: '60px',
                    lineHeight: '60px',
                    top: '100px !important'

                }
            },
            {
                "max-width": '1280px',
                css: { top: '80px !important', width: '200px !important', height: '60px !important', lineHeight: '60px !important' }
            }
        ]

    }

})

const CallToAction = () => {

    const styled = useStyle(styles)

    return <Link to={AppWebPaths.contact} className={styled('c2a')}>Call Us</Link>
    
}

const Heading = () => {

    const styled = useStyle(styles)

    return <div className={styled('headingContainer')}>

        <Suspense fallback={
            <ContainerPlaceholder className={styled('headBgAdjust')}/>
        }>
            <HeadingBackground className={styled('headBgAdjust', 'headBgAnimated')}/>
        </Suspense>

        <span className={styled('headingText')}>
            We provide solutions to make your business better
        </span>

        <CallToAction/>

        <Suspense fallback={
            <ContainerPlaceholder className={styled('headSepAdjust', 'headSep')}/>
        }>
            <HeadingSeparator className={styled('headSepAdjust')}/>
        </Suspense>

    </div>

}

export default Heading