import React from 'react'
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { ExtendHTMLProps } from '../../../types/ExtendProps'

const sepPic = require('../../../../../assets/img/heading-separator.webp')

const { styles } = createStyleSheet({

    headSep: {
        background: `url(${sepPic.default})`,
        backgroundPosition: '50% 50%',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
    }

})

const HeadingSeparator = (props: ExtendHTMLProps<HTMLDivElement>) => {

    const styled = useStyle(styles)

    return <div {...props} className={styled('headSep', props.className as unknown as null || null)}></div>

}

export default HeadingSeparator