import React from "react"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { ExtendHTMLProps } from "../../../types/ExtendProps"

const pic = require('../../../../../assets/img/logo.webp')

const { styles } = createStyleSheet({

    logopic: {

        background: `url(${pic.default})`,
        backgroundSize: '100% 100%',

    }

})

const Logo = (props: ExtendHTMLProps<HTMLDivElement>) => {

    const styled = useStyle(styles)

    return <div {...props} className={styled('logopic', props.className as undefined)}></div>
    
}

export default Logo