

export const AppWebPaths = {

    main: '/',
    contact: '/contact',

    custom_page: '/page/:name',

    article: '/article/:name',

    404: '/whoops'

}