import React, { useEffect, useState } from "react"
import { Switch, useLocation } from "react-router-dom"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { ExtendHTMLProps } from "../../types/ExtendProps"

const sheet = createStyleSheet({

    fadein: {

        transition: 'transform .1s ease-out, opacity .1s ease-out',
        opacity: '1',
        transform: 'scaleY(1)',
        transformOrigin: 'center top'

    },

    fadeout: {

        transition: 'transform .2s ease-out, opacity .2s ease-out',
        opacity: '0',
        transform: 'scaleY(0.3)',
        transformOrigin: 'center top'

    }

})

type TransitionedContentAreaState = {

    location: ReturnType<typeof useLocation>,
    transition: 'fadein' | 'fadeout'

}

export const TransitionedContentArea = (
    props: ExtendHTMLProps<HTMLDivElement>
) => {

    const location = useLocation(),
        [state, setState] = useState<TransitionedContentAreaState>({location, transition: 'fadein'})

    const styled = useStyle(sheet)

    useEffect(() => {

        if(location.pathname != state.location.pathname) {
            setState({ ...state, transition: 'fadeout' })
        }

    }, [location])

    return <div className={styled(state.transition)} onTransitionEnd={() => {

        if(location.pathname != state.location.pathname)
            setState({ location, transition: 'fadein' })

    }}>
        
        <Switch location={state.location}>

            {props.children}

        </Switch>
        
    </div>

}