

export const Colors = {

    mainInteract: '#12293c',
    mainInteractDark: '#0e1f32',
    mainInteractHighlight: '#44706a',
    mainOutliner: '#87bac3',
    interactText: '#fff',
    mainHeading: '#fff',
    scrollbarTrack: '#fff',
    sectionText: '#000',
    sectionBackground: '#fff',
    scrollbar: 'rgba(0,0,0,0.3)'

}