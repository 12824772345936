import React from "react"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { ExtendHTMLProps } from "../../../types/ExtendProps"

const bg = require('../../../../../assets/img/block-section-deco.webp')

const { styles } = createStyleSheet({

    deco: {

        background: `url(${bg.default})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'repeat-x',

    }

})

const SectionDecoBlock = (props: ExtendHTMLProps<HTMLDivElement>) => {

    const styled = useStyle(styles)

    return <div {...props} className={
        styled('deco', props.className as unknown as null)
    }></div>

}
export default SectionDecoBlock