import React, { createRef, useEffect } from "react"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { Colors } from "../../theme/Colors"
import { ExtendHTMLProps } from "../../types/ExtendProps"

const { styles } = createStyleSheet({

    showcase: {

        position: 'relative',
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
        padding: '3px 0px',
        textAlign: 'center',

    },

    scTile: {

        display: 'inline-block',
        flexDirection: 'row',
        verticalAlign: 'top',
        width: '180px',
        height: '260px',
        margin: '0 10px',
        backgroundColor: Colors.sectionBackground,
        boxShadow: '0px 0px 8px '+Colors.sectionText,
        zIndex: 1,
        textAlign: 'center',
        position: 'relative'

    },

    sctIcon: {

        width: '80px',
        height: '80px',
        borderRadius: '45px',
        display: 'block',
        position: 'relative',
        left: 0, right: 0, top: '20px', margin: '0 auto',
        border: '3px solid '+Colors.mainInteract

    },

    sctContent: {

        position: 'relative',
        top: '30px',
        width: '100%',
        fontStyle: 'italic',

    }

})

interface ShowcaseTileProps {

    icon?: string
    iconProps?: ExtendHTMLProps<HTMLDivElement>

}

export const ShowcaseTile = (
    props: ShowcaseTileProps & ExtendHTMLProps<HTMLDivElement>
) => {

    const styled = useStyle(styles)

    const tileRef = createRef<HTMLDivElement>()

    const { id } = props

    useEffect(() => {

        if(!tileRef || !tileRef.current) return;

        let appeared = false,
            interval : NodeJS.Timeout,
            delayTimeout: NodeJS.Timeout

        const onScreenAppearance = function () {

            if(appeared || !tileRef || !tileRef.current) return

            const item = tileRef.current!,
                rect = item.getBoundingClientRect()

            if(rect.top + rect.height < window.screen.availHeight) {
                appeared = true
                startAnimationCycle()
            }

        }

        const startAnimationCycle = () => {

            const animationStart = new Date().getTime(),
                idx = (+(id || 0)),
                RANGE = Math.round(document.body.getBoundingClientRect().width / 2),
                DURATION = 500, // .5s
                DELAY = (idx * 75) || 1 // .3s

            const animate = () => {

                if(!tileRef || !tileRef.current) return;

                const ratio = ((new Date().getTime() - animationStart) / (DURATION + DELAY)) ** 0.3

                if(ratio >= 1) {

                    tileRef.current.style.left = '0'
                    interval && clearInterval(interval)
                    return;

                }

                tileRef.current.style.left = ((idx + 1) * (RANGE - Math.round(RANGE * ratio)))+'px'

            }

            delayTimeout = setTimeout(() => {
                interval = setInterval(animate, 1)
            }, DELAY);

        }

        onScreenAppearance()

        window.addEventListener('scroll', onScreenAppearance)

        return () => {
            interval && clearInterval(interval)
            delayTimeout && clearTimeout(delayTimeout)
            window.removeEventListener('scroll', onScreenAppearance)
        }

    })

    return <div className={styled('scTile', props.className as unknown as null)} ref={tileRef} style={{left: '100vw'}}>
        <div className={styled('sctIcon')} {...props.iconProps} style={props.icon ? { background: `url(${props.icon})` } : {}}></div>
        {
            props.children &&
            <div className={styled('sctContent')}>{props.children}</div>
        }
    </div>

}

interface ShowcaseProps {



}

export const Showcase = (
    props: ShowcaseProps & ExtendHTMLProps<HTMLDivElement>
) => {

    const styled = useStyle(styles)

    return <div className={styled('showcase', props.className as unknown as null)}>
        {props.children}
    </div>

}