import React, { lazy, Suspense } from "react"
import { Link } from "react-router-dom"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { AppWebPaths } from "../../../common/paths/AppWebPaths"
import { Colors } from "../../theme/Colors"
import { ContainerPlaceholder } from "../lazy/assets/fallbacks/ContainerPlaceholder"
import { CookiePolicyPrompt } from "../misc/CookiePolicy"

const HeadingSeparator = lazy(() => import("../lazy/assets/HeadingSeparator"))

const ContactIcon = lazy(() => import('../lazy/assets/ContactIcons'))

const { styles } = createStyleSheet({

    footer: {

        position: 'relative',
        background: Colors.mainInteract,
        paddingTop: '10px',
        color: Colors.interactText,
        textAlign: 'center'

    },

    footerTitle: {

        marginTop: '45px',

    },

    social: {

        height: '208px',
        paddingTop: '96px',
        borderBottom: '3px solid '+Colors.mainOutliner,

    },

    socialWrap: {

        display: 'inline-block',
        verticalAlign: 'top',
        userSelect: 'none',

        transition: 'transform .3s ease-out',
        transform: 'scale(1,1)',
        transformOrigin: 'center',
        margin: '0 20px',
        cursor: 'pointer',

        '&:hover': {

            transform: 'scale(1.2,1.2)'

        }

    },

    socialIcon: {

        height: '96px !important',
        width: '96px !important',
        display: 'inline-block',
        verticalAlign: 'top',
        
    },

    socialLabel: {

        lineHeight: '96px',
        fontSize: '24px',
        padding: '0 4px',
        display: 'inline-block',
        verticalAlign: 'top',
        width: 'auto',
        textAlign: 'left',

    },

    links: {

        height: '120px',
        lineHeight: '120px',
        fontSize: '22px',
        fontWeight: 'bold',

        '& a': {

            padding: '0 15px',

        }

    },

    footSepAdjust: {

        width: '100%',
        height: '15vw',
        position: 'absolute',
        top: '-7vw',
        transform: 'scaleY(-1) scaleX(-1)'

    }

})

export const Footer = () => {

    const styled = useStyle(styles)

    return <div className={styled('footer')}>

        <Suspense fallback={
            <ContainerPlaceholder className={styled('footSepAdjust', 'headSep')}/>
        }>
            <HeadingSeparator className={styled('footSepAdjust')}/>
        </Suspense>

        <h1 className={styled('footerTitle')}>Cursive Information Consulting</h1>

        <div className={styled('social')}>

            {
                [
                    <span key='phone' className={styled('socialLabel')}>XXX-XX-XX</span>,
                    <span key='email' className={styled('socialLabel')}>email@example.com</span>,
                    <span key='insta' className={styled('socialLabel')}>instagram.com/username</span>,
                    <span key='fb' className={styled('socialLabel')}>fb.me/id1337</span>
                ].map(
                    ($, idx) => <div key={idx} className={styled('socialWrap')}>
                        <Suspense key={idx} fallback={
                            <ContainerPlaceholder className={styled('socialIcon', 'contactIcon')}/>
                        }>
                            <ContactIcon idx={idx} className={styled('socialIcon')}/>
                        </Suspense>
                        {$}
                    </div>
                )
            }
            

        </div>

        <div className={styled('links')}>
            <Link to='#'>Products</Link>
            <Link to='#'>Solutions</Link>
            <Link to='#'>Jobs</Link>
            <Link to={AppWebPaths.contact}>Contacts</Link>
        </div>

        <CookiePolicyPrompt/>

    </div>

}