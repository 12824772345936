import React from "react"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import { ExtendHTMLProps } from "../../../types/ExtendProps"

const icons = require('../../../../../assets/img/contact-icons.webp')

const { styles } = createStyleSheet({

    contactIcon: {

        background: `url(${icons.default})`,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',

        '&[data-icon-idx="0"]': {
            backgroundPosition: 'left 0 top 0'
        },
        '&[data-icon-idx="1"]': {
            backgroundPosition: 'left 33% top 0'
        },
        '&[data-icon-idx="2"]': {
            backgroundPosition: 'left 66% top 0'
        },
        '&[data-icon-idx="3"]': {
            backgroundPosition: 'left 100% top 0'
        },

    }

})


const ContactIcon = (props: { idx: number } & ExtendHTMLProps<HTMLDivElement>) => {

    const styled = useStyle(styles)

    return <div data-icon-idx={props.idx} {...props} className={styled('contactIcon', props.className as unknown as null)}></div>

}

export default ContactIcon