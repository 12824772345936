import { createStyleSheet } from 'stylight';
const MainFont = require('../../../assets/fonts/notosans-regular-webfont.woff2');

const theme = createStyleSheet({

    mixins: {

        '@font-face': {

            fontFamily: "'Noto Sans'",
            //@ts-ignore
            src: `url(${MainFont.default}) format('woff2')`

        },

        body: {

            fontFamily: "'Noto Sans', 'Arial', sans-serif",
            position: 'relative',
            margin: '0',

        },

        a: {

            textDecoration: 'none',
            color: 'inherit',
            outline: 'none'

        }

    }

})

export default theme