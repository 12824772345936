import React from 'react'
import { useStyle } from 'stylight/react'
import theme from '../../theme/Theme'

const InjectTheme = () => {

    useStyle(theme.styles)

    return <></>

}

export default InjectTheme