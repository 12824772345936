import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route } from 'react-router-dom'
import { Styled, StyleRenderer } from 'stylight/react'
import { AppWebPaths } from '../../common/paths/AppWebPaths'
import { NavigationPanel } from './elements/Navigation'
import { Footer } from './elements/Footer'
import { TransitionedContentArea } from './main/TransitionedContentArea'
import { ContactPage } from './main/pages/Contact'
import { LandingPage } from './main/pages/Landing'
import { Scrollbar } from './misc/Scrollbar'

const InjectTheme = lazy(() => import('./lazy/InjectTheme'))

export const App = () => {

    return <Styled>

        <Helmet><title>Cursive Consulting</title></Helmet>
        <Helmet><link rel='icon' type='image/png' href='/favicon.png' sizes='128x128'/></Helmet>

        <BrowserRouter>
        
            <NavigationPanel/>
            
            <TransitionedContentArea>

                <Route exact path={AppWebPaths.main}>
                    <LandingPage/>
                </Route>

                <Route exact path={AppWebPaths.contact}>
                    <ContactPage/>
                </Route>
            
            </TransitionedContentArea>
            
            <Footer/>
        
        </BrowserRouter>

        <Scrollbar/>
        <StyleRenderer wrap={styles => <Helmet>{styles}</Helmet>}/>

        <Suspense fallback={null}><InjectTheme/></Suspense>

    </Styled>

}