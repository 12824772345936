import React from "react"
import { Helmet } from "react-helmet"
import { Section } from "../../elements/Section"

export const ContactPage = () => {

    return <Section>
        <Helmet title='Cursive Information Consulting - Contacts'/>
        <Helmet>
            <meta
                name='description'
                content={
                    'Contact Cursive Information Consulting company representative to discuss '+
                    'further partnership opportunities and to get various offers to improve your own business'
                }
            />
        </Helmet>
        <h2>Contact us</h2>
    </Section>

}