import React, { lazy, Suspense } from "react";
import { createStyleSheet } from "stylight";
import { useStyle } from "stylight/react";
import { Colors } from "../../theme/Colors";
import { ExtendHTMLProps } from "../../types/ExtendProps";
import { ContainerPlaceholder } from "../lazy/assets/fallbacks/ContainerPlaceholder";

const SectionDecoBlock = lazy(() => import('../lazy/assets/SectionDeco'))

const { styles } = createStyleSheet({

    section: {

        color: Colors.sectionText,
        position: 'relative',

        '& > :is(h1, h2)': {

            textAlign: 'center',
            fontSize: '64px',
            fontStyle: 'italic',
            margin: '16px 0px',

        },

    },

    sectionDecorated: {

        paddingBottom: '15vw',

    },

    decoFloat: {

        position: 'absolute',
        height: '15vw',
        width: '100%',
        zIndex: -1,
        bottom: '0',

    }

})

export const Section = (props: ExtendHTMLProps<HTMLDivElement> & { nodeco?: boolean }) => {

    const styled = useStyle(styles)

    return <div {...props} className={
        styled('section', props.nodeco ? null : 'sectionDecorated', props.className as unknown as null)
    }>
        {props.children}
        {
            !props.nodeco && 
            <Suspense fallback={<ContainerPlaceholder className={styled('decoFloat', 'deco')}/>}>
                <SectionDecoBlock className={styled('decoFloat')}/>
            </Suspense>
        }
    </div>

}