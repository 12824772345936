import React from "react"
import { Helmet } from "react-helmet"
import { createStyleSheet } from "stylight"
import { useStyle } from "stylight/react"
import Heading from "../../elements/Heading"
import { Section } from "../../elements/Section"
import { Showcase, ShowcaseTile } from "../../elements/ShowcaseTile"

const { styles } = createStyleSheet({

    subTitleText: {

        textAlign: 'center',
        fontSize: '36px'

    },

    showcaseAdjust: {

        marginBottom: '-40px'

    }

})

export const LandingPage = () => {

    const styled = useStyle(styles)

    return <>
    
        <Heading/>
        <Helmet title='Cursive Information Consulting - Home page'/>
        <Helmet>
            <meta
                name='description'
                content={
                    'Cursive Information Consulting is an IT consulting and software development company, targeted to provide '+
                    'complete solutions for variety of businesses in information technology and internet marketing fields'
                }
            />
        </Helmet>
        <Section style={{paddingTop: '5vw'}}>
            <h2>We know the technology</h2>
            <p className={styled('subTitleText')}>Lorem ipsum and other stuff</p>
            <Showcase className={styled('showcaseAdjust')}>
                <ShowcaseTile id='1'>This is our first showcase item text</ShowcaseTile>
                <ShowcaseTile id='2'>This is our second showcase item text</ShowcaseTile>
                <ShowcaseTile id='3'>This is our third showcase item text</ShowcaseTile>
            </Showcase>
        </Section>
    
    </>

}