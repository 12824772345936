import React from "react"

export const CookiePolicyPrompt = () => {

    return <>
    
        Just in case you&apos;re curious: we are not using any cookies ;) Have a nice time!
        <br/><br/>
    
    </>

}