import React, { useEffect, useRef } from "react"
import { useStyle } from "stylight/react"
import { Colors } from "../../theme/Colors"

const TRACK_HEIGHT = 100

export const Scrollbar = () => {

    const styled = useStyle({

        scrollbar: {

            position: 'fixed',
            top: '0px',
            right: '0px',
            height: '100vh',
            background: Colors.scrollbar,
            userSelect: 'none',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            width: '12px'

        },

        track: {

            height: TRACK_HEIGHT+'px',
            width: '8px',
            cursor: 'pointer',
            left: '2px',
            position: 'relative',
            zIndex: 999,
            background: Colors.scrollbarTrack

        },

        mixins: {

            '::-webkit-scrollbar': {
                display: 'none',
                width: '0'
            },

            body: {

                scrollbarWidth: 'none',
                msOverflowStyle: 'none'

            }

        }

    })

    const trackRef = useRef<HTMLDivElement>(null),
        barRef = useRef<HTMLDivElement>(null)

    useEffect(() => {

        if(document.body.clientHeight <= window.innerHeight && barRef.current)
            barRef.current.style.display = 'none'

        // wheel/arrow scroll handler

        const onscroll = function () {

            if(!trackRef.current || !barRef.current) return;

            const docHeight = document.body.getBoundingClientRect().height,
                screenHeight = window.innerHeight,
                scrollRegion = docHeight - screenHeight,
                barHeight = barRef.current.getBoundingClientRect().height,
                barRegion = barHeight - TRACK_HEIGHT,
                scrollY = window.scrollY

            const offset = scrollY / scrollRegion * barRegion

            trackRef.current.style.top = Math.round(offset)+'px'

        }

        onscroll()
        window.addEventListener('scroll', onscroll)

        // capture & move handler
        
        const oncapturemove = function(e){

            if(!trackRef.current || !barRef.current) return;

            const { clientY } = e as { clientY: number }

            const docHeight = document.body.getBoundingClientRect().height,
                screenHeight = window.innerHeight,
                scrollRegion = docHeight - screenHeight,
                barHeight = barRef.current.getBoundingClientRect().height

            const offsetRatio = clientY / barHeight,
                scrollY = scrollRegion * offsetRatio

            window.scrollTo({ top: Math.round(scrollY) })

        }

        if(!trackRef.current || !barRef.current) return;

        // mouse capture/release handlers

        let captured = false

        const oncapture = function(){ captured = true }

        const onrelease = function(){ captured = false }

        const onmousecapturemove = function(e) { if(captured) return oncapturemove(e) }

        trackRef.current.addEventListener('mousedown', oncapture)
        window.addEventListener('mouseup', onrelease)

        window.addEventListener('mousemove', onmousecapturemove)

        return () => {

            window.removeEventListener('scroll', onscroll)
            window.removeEventListener('mousemove', onmousecapturemove)
            window.removeEventListener('mouseup', onrelease)

            if(trackRef.current) {

                trackRef.current.removeEventListener('mousedown', oncapture)

            }
        }

    })

    return <div className={styled('scrollbar')} ref={barRef}>
        <div className={styled('track')} ref={trackRef}></div>
    </div>
    
}